<template>
  <div>
    <h3>What is a tangent to a circle?</h3>
    <p>
      A line that intersects a circle at exactly one point is called a tangent to a circle. The point where
      such a line touches the circle is called the point of contact. An example of a tangent drawn to a circle is shown
      in the figure below. In this example, the point of contact of the tangent is \(P\).
    </p>
    <div align="center">
      <figure>
        <img src="/assets/tangent-1.png" width="400px"></img>

        <figcaption>Fig 1: Tangent to a circle </figcaption>
      </figure>
    </div>
    <h3>Number of tangents from a point</h3>
    <p>
      The number of tangents that can be drawn from a point to a circle depends upon the location of the point relative to the circle.
      Therefore, we have three possible cases depending on the position of the point from where the tangent is to be drawn:
    </p>
    <div align="center">
      <figure>
        <img src="/assets/tangent-2.png" width="400px"></img>

        <figcaption>Fig 2: Tangents to a circle drawn from a point that lies outside of the circle. </figcaption>
      </figure>
    </div>
    <ul class="a">
      <li>
        <h5> Point on the circumference of the circle </h5>
        When the point from where the tangent is to be drawn lies on the circumference of the circle, only one tangent to
        the circle can be drawn which passes through that point.
      </li>
      <li> <h5> Point exterior to the circle </h5> When the point lies outside the circle, exactly two tangents can be drawn from the exterior  point to the circle.</li>
      <li>
        <h5> Point interior to the circle </h5> When the point lies inside the circle, no tangent can be drawn from the point to the circle as any line
        drawn from the interior point inside the circle would intersect the circle at two points.
      </li>
    </ul>
    <br>
    <h3>Properties of Tangents </h3>
    <p>Next we will learn two important properties of the tangents of a circle. </p>

    <h5>Property 1</h5>
    <p>The tangent to a circle is perpendicular to the radius of the circle at the point of contact.</p>
    <h5>Property 2</h5>
    <p>
      We already know that from point outside a circle, two tangents can be drawn to the circle.
      Now we shall study about the length of two tangents drawn from an exterior point. There is an important theorem
      that states that the length of two tangents drawn from an exterior point to a circle are same.
    </p>
    <p><b>Theorem:</b> The length of two tangents drawn from an external point to a circle are same.</p>
    <h3>MagicGraph | Tangents from a Point to the Circle</h3>
    <p>
      In this MagicGraph, you will learn about how many tangents can be drawn from a point to a circle.
      You are given a point P that can be dragged around. 
      Drag point P around and observe the number of tangents that can be drawn from it to the circle.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'TangenttoACircle',
  created: function () {
    // Store mutations
    this.$store.commit('navigation/resetState');
    let title = 'Tangent & Secant of a Circle';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Square Formula', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Tangent to a Circle',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about square formula'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
