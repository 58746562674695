// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    //placeLine(board, Pt1, Pt2, width, cols)
    placeLabel,
    placePoint,
    placeGlider,
    placeRuler,
    placeCircle,
//placeCircle(board, Pt1, Pt2, width, cols)
    placeLeftText,
    placeSliderSwitch
} from '../../../common/edliy_utils-integral';
const Boxes = {
box1: function () {
JXG.Options.point.showInfoBox=false;
JXG.Options.point.highlight=false;
JXG.Options.text.highlight=false;
JXG.Options.circle.highlight=false;
JXG.Options.line.highlight=false;
JXG.Options.text.fixed=true;
JXG.Options.curve.highlight=false;
JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-1, 16, 16, -1],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.options.layer['image']=14;
brd1.suspendUpdate();
//Title
makeResponsive(brd1);
placeTitle(brd1, 'What is a Tangent?', '(Drag point P and observe how many tangents can be drawn from it to the circle)');
//Variables
var subtitle = brd1.create('text', [9, 13, ''],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

//Variables
var centerx = 8;
var centery = 8;
var radius = 2;

var initial_p_Arrow = 0.5;

var sizeOk = 1;
var inpX = 1;
var inpY =12;

var size_arr = 1;

let switchStatus = true;

//Circle
//var c1 = brd1.create('circle', [[centerx, centery], [centerx + radius, centery]], {strokeColor: '#5B43FF', strokeWidth: 4, fixed:true});
var c1 = placeCircle(brd1, [centerx, centery], [centerx + radius, centery], 4, '#5B43FF');
//Gliders
//var line2 = brd1.create('line', [[centerx, centery - 2*radius ], [centerx +2, centery - 2*radius ]], {visible: false,fixed: true, strokeColor: 'black', strokeWidth: 1});
var line2 =brd1.create('circle',[[centerx, centery], [centerx, centery-radius-4.1]],{strokeColor: '#5B43FF', strokeWidth:1, dash:1, fixed:true});
//var line2 = placeCircle(brd1, [centerx, centery], [centerx, centery-radius-4.1], 2, 'black');
//line2.setAttribute({dash:1});
//Attractors
var pt1 = brd1.create('point', [centerx - radius, centery + radius +1],{face:'o' , name:'A', size:2, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pt2 = brd1.create('point', [centerx - radius, centery - radius -1],{face:'o' , name:'', size:2, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pt3 = brd1.create('point', [centerx + radius, centery + radius +1],{face:'o' , name:'', size:2, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var pt4 = brd1.create('point', [centerx + radius, centery - radius -1],{face:'o' , name:'', size:2, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//Attractors
var attr1 = placePoint(brd1, centerx - (4*Math.sqrt(3,2)/3)*radius, centery - 2*radius, 3, 'orange', 'black');
//brd1.create('point', [centerx - (4*Math.sqrt(3,2)/3)*radius,centery - 2*radius],{face:'o' , name:'', size:4, strokeColor: 'orange', fillColor: 'orange', withLabel:true, fixed:true, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var attr2 = placePoint(brd1, centerx + (4*Math.sqrt(3,2)/3)*radius, centery - 2*radius, 3, 'orange', 'black');
//brd1.create('point', [centerx + (4*Math.sqrt(3,2)/3)*radius,centery - 2*radius],{face:'o' , name:'', size:4, strokeColor: 'orange', fillColor: 'orange', withLabel:true, fixed:true, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//DragMe
//var point1 = brd1.create('point', [centerx,centery + 2*radius],{attractors: [pt1, pt2, pt3, pt4], attractorDistance: 0.8 , snatchDistance: 0.5, face:'o' , name:'P (Fixed)', size:4, strokeColor: '#FF3B3B', fillColor: 'white', withLabel:true, fixed:true, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var point1 = placePoint(brd1, centerx, centery + 2*radius, 3, 'white', '#FF3B3B');
point1.setAttribute({fixed:true, name:'P(Fixed)'});
//point1.setLabelAttributes({fontSize:22});
//var dragMe = brd1.create('glider', [8,0, line2],{attractors: [attr1, attr2], attractorDistance: 0.8 , snatchDistance: 1, face:'o' , name:'Q (Drag Me!)', size:4, strokeColor: '#FF3B3B', fillColor: 'white', withLabel:true, fixed:false, visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var dragMe = placeGlider(brd1, line2, 8, 0, 3, 'white', '#FF3B3B');
dragMe.setAttribute({attractors: [attr1, attr2], attractorDistance:1. , snatchDistance: 1, name:'Q(Drag Me!)', label:{fontSize:function(){return 22*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

//placeLabel(brd1, dragMe, 'Q(Drag Me!)', 0, 0);
//Tangent, secant
//var seg1 = brd1.create('segment', [point1, dragMe]);
var seg1 = placeLine(brd1, [centerx,centery + 2*radius], [()=>dragMe.X(), ()=>dragMe.Y()], 3, 'black');
//Intersection
var i1 = brd1.create('intersection', [c1, seg1, 0], {name: '', strokecolor:'black', face:'circle', size:()=>3*brd1.canvasHeight/800, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var i2 = brd1.create('intersection', [c1, seg1, 1], {name: '', strokeColor:'black',face:'circle', size:()=>3*brd1.canvasHeight/800, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

//Texts
var txtMove = brd1.create('text', [8, 0, ''],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
var txtMove2 = brd1.create('text', [7,0, 'PQ is a tangent'],{display:'internal', visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
var txtMove3 = brd1.create('text', [6.9,0, 'PQ is a secant'],{display:'internal', visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);
var txtMove4 = brd1.create('text', [8,0, 'PQ is a non-intersecting line'],{display:'internal', visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}},);

//Radius line
var seg_radius = brd1.create('segment', [[centerx, centery], i1], {fixed: true, strokeColor: '#5B43FF', strokeWidth: 3, dash: 3, visible: function(){if((point1.X() == pt1.X() && dragMe.X() == pt1.X()) || (point1.X() == pt3.X() && dragMe.X() == pt3.X()) ){return true} else{return false}}});

//Angle
var angle1 = brd1.create('angle', [seg_radius, seg1 , -1 , -1],{name:'90' , visible: true,  strokeColor:'blue', fillColor:'blue',visible: function(){if((point1.X() == pt1.X() && dragMe.X() == pt1.X()) ){return true} else{return false}}, label: {color: 'blue', offset: [10 , -10],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var angle2 = brd1.create('angle', [seg_radius, seg1 , -1 , 1],{name:'90' , visible: true,  strokeColor:'blue', fillColor:'blue', visible: function(){if((point1.X() == pt3.X() && dragMe.X() == pt3.X()) ){return true} else{return false}}, label: {color: 'blue', offset: [-10 , -10],fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

//Arrow
var p_arrow = brd1.create('point', [5,function(){return shuffle();}],{face:'o' , name:'', size:2, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var im_arrow = brd1.create('image', ['/assets/choose.svg', [function(){return p_arrow.X() - size_arr/2}, function(){return p_arrow.Y() - size_arr/2}], [size_arr, size_arr]], {visible: false, fixed: true});

//Images
var shuffle1 = brd1.create('image', ['/assets/shuffle.svg', [10, 1], [1,1]], {visible: false, fixed: true});
var ok1 = brd1.create('image', ['/assets/check.svg', [50,50], [sizeOk, sizeOk]], {visible: function(){if(p_arrow.Y() == tangent()){return true}else{return false;}}, fixed: true});

//Switch
let switch3 = brd1.create('text', [13.5,15,'<label class="switch" ><input type="checkbox"><span class="slider"></span></label>'], {fixed: true});

//Functions
function tangent(){
    if( (dragMe.X() == attr1.X()) || (dragMe.X() == attr2.X()) ){
        txtMove.setText('PQ is a Tangent!');
        return 2.5;
    }
    else if(i1.X() != 0){
        txtMove.setText('PQ is a Secant!');
        return 1.5;
    }
    else{
        txtMove.setText('PQ is a Non-intersecting Line!');
        return 0.5;
    }
}

function shuffle(){

    if(initial_p_Arrow <= 0.1){
        subtitle.setText('Can you find a non - intersecting line to the circle?');
        return 0.5;
    }
    else if(initial_p_Arrow > 0.1 && initial_p_Arrow <= 0.2){
        subtitle.setText('Can you find a secant line to the circle?');
        return 1.5;
    }
    else if(initial_p_Arrow > 0.2 && initial_p_Arrow <= 0.3){
        subtitle.setText('Can you find a tangent line to the circle?');
        return 2.5;
    }
    else if(initial_p_Arrow > 0.3 && initial_p_Arrow <= 0.4){
        subtitle.setText('Can you find a non - intersecting line to the circle?');
        return 0.5;
    }
    else if(initial_p_Arrow > 0.4 && initial_p_Arrow <= 0.5){
        subtitle.setText('Can you find a secant line to the circle?');
        return 1.5;
    }
    else if(initial_p_Arrow > 0.5 && initial_p_Arrow <= 0.6){
        subtitle.setText('Can you find a tangent line to the circle?');
        return 2.5;
    }
    else if(initial_p_Arrow > 0.6 && initial_p_Arrow <= 0.7){
        subtitle.setText('Can you find a non - intersecting line to the circle?');
        return 0.5;
    }
    else if(initial_p_Arrow > 0.7 && initial_p_Arrow <= 0.8){
        subtitle.setText('Can you find a secant line to the circle?');
        return 1.5;
    }
    else if(initial_p_Arrow > 0.8 && initial_p_Arrow <= 0.9){
        subtitle.setText('Can you find a tangent line to the circle?');
        return 2.5;
    }
    else{
        subtitle.setText('Can you find a tangent line to the circle?');
        return 2.5;
    }
}

function switchToggle(){

    if (switchStatus == false) {
      switchStatus = true;
      learn_mode();
        }
    else {
        switchStatus = false;
        test_mode();
      }
}

function test_mode(){
    txtMove.setAttribute({visible: false});
    txtMove2.setAttribute({visible: true});
    txtMove3.setAttribute({visible: true});
    txtMove4.setAttribute({visible: true});
    im_arrow.setAttribute({visible: true});
    shuffle1.setAttribute({visible: true});
    subtitle.setAttribute({visible: true});

    ok1.moveTo([p_arrow.X() - 1 - sizeOk, p_arrow.Y() - sizeOk/2], 10);
}

function learn_mode(){
    txtMove.setAttribute({visible: true});
    txtMove2.setAttribute({visible: false});
    txtMove3.setAttribute({visible: false});
    txtMove4.setAttribute({visible: false});
    shuffle1.setAttribute({visible: false});
    subtitle.setAttribute({visible: false});
    im_arrow.setAttribute({visible: false});

    ok1.moveTo([50,50], 10);
}

switch3.on('down', function(){return switchToggle()});

//Shuffle function
shuffle1.on('down',function(){

    initial_p_Arrow = Math.random();

    ok1.moveTo([p_arrow.X() - 1 - sizeOk, shuffle() - sizeOk/2], 10);

    //point1.moveTo([2,12], 10);
    //dragMe.moveTo([2,4], 10);
});

//Label
shuffle1.setLabel('Shuffle')
shuffle1.label.setAttribute({visible:false, offset:[5, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
shuffle1.on('over', function () {this.label.setAttribute({visible:true});});
shuffle1.on('out', function () {this.label.setAttribute({visible:false});});

brd1.unsuspendUpdate();
//Title and tetxsvar subtitle = brd1.create('text', [7, 14, ''],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);

}
}
export default Boxes;
